<template>
    <div class="container limit-mid agradecimento">
        <h1>Agradecemos o contato, em breve retornaremos!</h1>
    </div>
</template>
<script>
import gsap from 'gsap'
export default {
    mounted(){
        setTimeout(()=>{
            gsap.to('.agradecimento h1', {
                opacity: 1,
                duration: 1
            })
        },1000)
        setTimeout(()=>{
            window.location.href="http://evahc.com.br"
        },4500)
    }
}
</script>

<style lang="scss">
.agradecimento{
    h1{
        opacity:0;
    }
}
</style>